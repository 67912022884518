import Vue from "vue"
import VueRouter from "vue-router"
import VueCookie from "vue-cookie"
import VueMq from "vue-mq"
import VueClipboard from "vue-clipboard2"
import Vue2TouchEvents from "vue2-touch-events"
import vuexI18n from "vuex-i18n"
import translationsFr from "./lang/fr.json"
import moment from "moment"
import numeral from "numeral"

import "./app-sw"
import router from "./router"
import store from "./store"

import App from "./App.vue"

/*
 * TOP OF MIND:
 * https://alligator.io/vuejs/vue-async-components-load-error/
 * https://vuejsdevelopers.com/2018/08/27/vue-js-form-handling-vuelidate/
 */

Vue.use(VueRouter)
Vue.use(VueCookie)
Vue.use(VueMq, {
    breakpoints: {
        xs: 481,
        sm: 551,
        md: 841,
        lg: 961,
        tl: 1025, // tablet landscape
        xl: 1281,
        xxl: 1441
    }
})
Vue.use(VueClipboard)
Vue.use(Vue2TouchEvents)

const lang = Vue.cookie.get("lang") || "en"
Vue.use(vuexI18n.plugin, store)
Vue.prototype.translate = (translation, options = {}, pluralization = null, lang_ovveride = null) => {
    // if(pluralization !== null) return Vue.i18n.translateIn(lang_ovveride || lang, translation, options, pluralization)
    return Vue.i18n.translateIn(lang_ovveride || Vue.i18n.locale(), translation, options, pluralization)
}
Vue.prototype.translated_field_name = (field, lang_ovveride = null) => {
    return `${field}_${lang_ovveride || Vue.i18n.locale()}`
}
Vue.prototype.translate_field = (object, field, lang_ovveride = null) => {
    if(!object) return ""
    return object[Vue.prototype.translated_field_name(field, lang_ovveride)] || object[field]
}

Vue.i18n.add("fr", translationsFr)
// Vue.i18n.add("en", translationsEn)
Vue.i18n.set(lang)

moment.updateLocale("en", {
    longDateFormat: {
        LT: "h:mm A",
        LTS: "h:mm:ss A",
        L: "MMMM Do",
        LL: "ddd, MMM D", // year added only if needed
        LLL: "ddd, MMM D Y",
        LLLL: "dddd, MMMM Do, YYYY"
    }
})
moment.locale("fr", {
    months: "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split("_"),
    monthsShort: "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split("_"),
    monthsParseExact: true,
    weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
    weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
    weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd D MMMM YYYY HH:mm"
    },
    calendar: {
        sameDay: "[Aujourd’hui à] LT",
        nextDay: "[Demain à] LT",
        nextWeek: "dddd [à] LT",
        lastDay: "[Hier à] LT",
        lastWeek: "dddd [dernier à] LT",
        sameElse: "L"
    },
    relativeTime: {
        future: "dans %s",
        past: "il y a %s",
        s: "quelques secondes",
        m: "une minute",
        mm: "%d minutes",
        h: "une heure",
        hh: "%d heures",
        d: "un jour",
        dd: "%d jours",
        M: "un mois",
        MM: "%d mois",
        y: "un an",
        yy: "%d ans"
    },
    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
    ordinal: function (number) {
        return number + (number === 1 ? "er" : "e")
    },
    meridiemParse: /PD|MD/,
    isPM: function (input) {
        return input.charAt(0) === "M"
    },
    meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? "PD" : "MD"
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4 // Used to determine first week of the year.
    }
})
moment.locale(lang)
Vue.prototype.$moment = moment

numeral.register("locale", "fr", {
    delimiters: {
        thousands: " ",
        decimal: ","
    },
    abbreviations: {
        thousand: "k",
        million: "m",
        billion: "b",
        trillion: "t"
    },
    ordinal: function (number) {
        return number === 1 ? "er" : "ème"
    },
    currency: {
        symbol: "$"
    }
})
numeral.locale(lang)
Vue.prototype.$numeral = numeral

const notifies = {
    methods: {
        $notify (message, type = "primary", dismiss_after = 7000, message_id = null) {
            this.$store.dispatch("SYSTEM_MESSAGE", {
                type,
                message,
                dismiss_after,
                message_id
            })
        },
        $error (message, dismiss_after = 7000, message_id = null) {
            this.$notify(message, "error", dismiss_after, message_id)
        },
        $warn (message, dismiss_after = 7000, message_id = null) {
            this.$notify(message, "warning", dismiss_after, message_id)
        },
        $success (message, dismiss_after = 7000, message_id = null) {
            this.$notify(message, "success", dismiss_after, message_id)
        }
    }
}
Vue.mixin(notifies)

// === FILTERS: v-bind="'I\'m good' | nibnut.slugify" OR {{ 'I\'m good' | nibnut.slugify }} OR this.nibnut_filter("nibnut.slugify", ["I'm good"])
const filters = {
    "nibnut.slugify": (value, replacement = "-") => {
        if(!value) return value
        return value.toLowerCase().replace(/[^a-zA-Z\-0-9]/g, replacement)
    },
    "nibnut.date_from_now": (date) => {
        return moment(date).fromNow()
    },
    "nibnut.date": (date, format = "YYYY-MM-DD") => { // YYYY-MM-DD HH:mm:ss
        if(!date) return ""
        return moment(date).format(format)
    },
    "nibnut.duration": (duration) => {
        return moment.duration(duration).humanize()
    },
    "nibnut.number": (number, format = "0,0[.]0", default_value = null) => {
        if(!number && default_value) return default_value
        return numeral(number).format(format)
    },
    "nibnut.currency": (number, format = "0,0[.]00", default_value = null) => {
        const amount = filters["nibnut.number"].apply(this, [number, format, default_value])
        if(lang === "fr") return `${amount}$`
        return `$ ${amount}`
    },
    "nibnut.percent": (number, format = "0,0[.]0", default_value = null) => {
        const amount = filters["nibnut.number"].apply(this, [number, format, default_value])
        if(lang === "fr") return `${amount} %`
        return `${amount}%`
    },
    "nibnut.number-field": (number, editable, format, default_value) => {
        if(editable) return number
        return filters["nibnut.number"].apply(this, [number, format, default_value])
    },
    "nibnut.phone": (phone) => {
        if(!phone) return ""
        const matches = phone.match(/^(\d{3})(\d{3})(\d{4})$/)
        if(matches) return `(${matches[1]}) ${matches[2]}-${matches[3]}`
        return ""
    },
    "nibnut.mailto": (email) => {
        if(!email) return ""
        return "mailto:" + email
    },
    "nibnut.telto": (phone) => {
        if(!phone) return ""
        return "tel:" + phone
    },
    "nibnut.ucwords": (text) => {
        if(!text) return text
        return text.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter) => {
            return letter.toUpperCase()
        })
    },
    "nibnut.truncate": (text, max_length, ellipsis = "...") => {
        const length = text.length
        if(length <= max_length) return text
        return text.substring(0, max_length - ellipsis.length) + ellipsis
    }
}
Vue.mixin({
    methods: {
        nibnut_filter (filter_name, filter_arguments) {
            return filters[filter_name].apply(this, filter_arguments)
        }
    }
})
Object.keys(filters).forEach(filter => {
    Vue.filter(filter, filters[filter])
})

// === DIRECTIVES
Vue.directive("nibnut-on-scroll", {
    inserted: (element, binding) => {
        let previous_scroll_position = { x: -1, y: -1 }
        const listener = (event) => {
            const scroll_position = { x: window.pageXOffset || document.documentElement.scrollLeft, y: window.pageYOffset || document.documentElement.scrollTop }
            const scroll_direction = {
                vertical: previous_scroll_position.y <= scroll_position.y, // true is down
                horizontal: previous_scroll_position.x <= scroll_position.x // true is left
            }
            if(binding && !!binding.value && binding.value({ event, scroll_direction, previous_scroll_position, scroll_position, element })) window.removeEventListener("scroll", listener)
            previous_scroll_position = scroll_position
        }
        window.addEventListener("scroll", listener)
    }
})

if(process.env.NODE_ENV === "development") {
    Vue.config.debug = true
    Vue.config.devtools = true
}

window.nibnut_app = new Vue({
    el: "#app",
    store,
    router,
    render: h => h(App)
})
